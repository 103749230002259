.search {
  z-index: 1;
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 20px 20px 0;
  background-color: #fff;
}

.map {
  /* width: 1000px;
  height: 600px; */
  width: 100%;
  height: 88vh;
}

.space {
  padding: 4px 3px !important;
  line-height: 14px;
}

.master {
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border: 1px solid blue;
  white-space: nowrap;
}

.nearby {
  color: #fff;
  border: 1px solid rgba(0, 0, 255, 0.22);
  background-color: rgb(235, 4, 4);
}