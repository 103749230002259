.reminder_styles {
  background-color: #F91D00;
  width: 155px;
  height: 35px;
  opacity: 0.7;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  line-height: 35px;
  text-align: center;
  transition: 0.5s;
}

.reminder_styles:hover {
  opacity: 1;
}

.inline {
  display: inline;
  cursor: pointer;
}

.width-50 {
  width: 50px;
  text-align: center;
}

.fixtop {
  z-index: 1;
  position: fixed;
  top: 100px;
  left: 260px;
  width: 1643px;
  border-left: 20px solid rgb(228, 233, 247);
  border-right: 20px solid rgb(228, 233, 247);
  border-top: 20px solid rgb(228, 233, 247);
  border-bottom: 15px solid rgb(228, 233, 247);
}