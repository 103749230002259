.left-top-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0px;
  left: 0;
  background: linear-gradient(135deg, #f3a75e, #f3a75e 50%, transparent 50%, transparent 100%);
}

.columns-border:first-child .left-top-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -12px;
  left: 0;
  background: linear-gradient(135deg, #f3a75e, #f3a75e 50%, transparent 50%, transparent 100%);
}

.left-top-type-span {
  color: white;
  transform: rotate(-45deg);
  margin-right: 15px;
  margin-top: 4px;
}


.right-top-type-span {
  color: white;
  transform: rotate(45deg);
  margin-left: 15px;
  margin-top: 4px;
}

.urgeOrder-type-right {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  background: linear-gradient(225deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
}

.columns-border:first-child .urgeOrder-type-right {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -12px;
  right: 0;
  background: linear-gradient(225deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
}



.columns-border {
  border-bottom: 1px solid #f0f0f0;
  /* padding: 8px 0; */
  width: calc(100% + 16px);
  margin: 0 -8px;
  height: 200px;
  position: relative;
}

.columns-border:last-child {
  border-bottom: none;
}

.columns-span {
  color: rgba(142, 141, 141, 100);
  font-size: 14px;
}

.columns3-type-span {
  color: red;
  font-size: 14px;
}

.columns1-date-span {
  color: rgba(142, 141, 141, 100);
  font-size: 14px;
  opacity: 0.5;
}

.handle {
  background-color: #F99200;
  opacity: 0.7;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.handle:hover {
  opacity: 1;
}

.inline {
  display: inline;
  cursor: pointer;
  width: 50px;
  border: 1px solid black;
  border-radius: 3px;
}

.width-50 {
  width: 50px;
  text-align: center;
}

.pageStyle {
  padding: 5px 10px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
}

.pageStyle:hover {
  background-color: #e6e6e6;
}

.after {
  padding: 10px;
  border: 1px dashed #ccc;
}

.pic-icon-E {
  font-size: 20px;
  display: none;
  /* color: red; */
  padding: 0 5px;
  transition: 0.8s;
  position: absolute;
  top: 40px;
  left: 35px;
}

.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.content-div {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 90px;
  transition: 0.5s;
}

.content-div2 {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  transition: 0.5s;
}

.pic {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.pic2 {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.pic:hover {
  background-color: rgba(0, 0, 0, 0.3);
}


.content-div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.pic-icon {
  position: relative;
  top: 86px;
  width: 98px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2px;
  cursor: pointer;
}

.content-video {
  position: relative;
  padding: 5px;
  width: 250px;
  height: 150px;
  margin-left: 8px;
  /* margin-bottom: 60px; */
  border: 1px solid #ccc;
  border-radius: 10px;
}

.content-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video {
  padding: 3px 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 1px solid #0BC069;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
}

.video-select {
  background-color: #0BC069;
  color: #fff;
}

.video-box {
  margin: 0 10px 10px;
  padding: 6px 10px;
  border: 1px dashed #ddd;
  border-radius: 5px;
}