.columns2-copy-button {
  width: 100px;
  padding: 5px 0px;
  background-color: #1890FF;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  color: white;
  opacity: 0.7;
}

.columns2-copy-button:hover {
  opacity: 1;
}

.btn {
  width: 100px;
  height: 35px;
  line-height: 33px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: white;
  background-color: #1890FF;
}