.total-credit {
  height: 56px;
  line-height: 56px;
}

.search-line {
  padding-top: 25px;
}

.input-service {
  padding-left: 20px;
  width: 250px;
  height: 35px;
}

.btn {
  width: 100px;
  height: 35px;
  line-height: 33px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.img {
  width: 20rpx;
  height: 20rpx;
}

.fixedwidth330 {
  width: 320px !important;
}

.fixedwidth220 {
  width: 220px !important;
}

.fixedwidth110 {
  width: 110px !important;
}

.fixedwidth100 {
  width: 80px !important;
}