.property {
  width: 85px;
}

.btn {
  width: 100px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
}

.input {
  width: 200px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.inputs {
  width: 100px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}