
.inline {
    display: inline;
    cursor: pointer;
    width: 50px;
    border: 1px solid black;
    border-radius: 3px;
}

.width-50 {
    width: 50px;
    text-align: center;
}
.pageStyle{
    padding: 5px 10px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
}
.pageStyle:hover{
    background-color: #e6e6e6;
}