.icon {
  width: 30px;
  height: 30px;
}

.line {
  width: 1300px;
  border: 1px dashed #BBB;
}

.box {
  margin: 0 10px 15px 0;
  width: 300px;
  height: 160px;
  border: 1px solid rgba(217, 217, 217, 100);
  border-radius: 5px;
}

.icon2 {
  width: 25px;
  height: 25px;
}