.title {
  height: 39px;
  border-bottom: 1px solid #D4D4D4;
}

.menu {
  margin-right: 1px;
  width: 135px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #D4D4D4;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}

.menu-select1 {
  color: #0BC069;
  background-color: #fff !important;
  border-bottom-color: #fff;
}

.menu-select2 {
  color: #fff;
  background-color: #2BA66D !important;
  border-bottom-color: #fff;
}



.menu-content {
  background-color: #fff;
}