:root {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.form_title {
  font-weight: 300;
  margin: 0;
  margin-bottom: 1.25rem;
}

.input {
  background-color: #fff;
  border: none;
  padding: 0.9rem 0.9rem;
  width: 340px;
  border-radius: 8px;
  /* font-size: 22px;
    padding: 16px 0;
    height: 36px;
    border: none !important;
    background-color:transparent ; */
}

.container_form {
  position: relative;
}

.animation-slide-left {
  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  z-index: 1;
}

.animation-slide-right {
  animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  z-index: 1;
}

.container_register {
  width: 900px;
  height: 400px;
  background-color: #e9e9e9;
  border-radius: 15px;

}

.container_signin {
  width: 450px;
  height: 400px;
  background-color: #e9e9e9;
  /* border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; */
  border-radius: 15px;
}

.container_signup {
  width: 450px;
  height: 400px;
  background-color: #e9e9e9;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}


.link {
  color: #333;
  font-size: 15px;
  margin: 25px 0 25px 0;
  text-decoration: none;
  cursor: pointer;
}

.link:hover {
  color: #1890ff;
}

.getCode {
  position: absolute;
  top: 10px;
  right: 5px;
  background-color: #008997;
  border-radius: 50px;
  color: #e9e9e9;
  cursor: pointer;
}


.container_overlay_signin {
  width: 450px;
  height: 400px;
  background-color: #e9e9e9;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: -10;
}

.container_overlay_signin1 {
  width: 450px;
  height: 400px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.3px 0.7px rgba(0, 0, 0, 0.126),
    0 0.9px 1.7px rgba(0, 0, 0, 0.179),
    0 1.8px 3.5px rgba(0, 0, 0, 0.224),
    0 3.7px 7.3px rgba(0, 0, 0, 0.277),
    0 10px 20px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(1px);
  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  z-index: 2;
}

.container_overlay_signup {
  width: 450px;
  height: 400px;
  background-color: #e9e9e9;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: -10;
}

.container_overlay_signup1 {
  width: 450px;
  height: 400px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.3px 0.7px rgba(0, 0, 0, 0.126),
    0 0.9px 1.7px rgba(0, 0, 0, 0.179),
    0 1.8px 3.5px rgba(0, 0, 0, 0.224),
    0 3.7px 7.3px rgba(0, 0, 0, 0.277),
    0 10px 20px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(1px);
  animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  z-index: 2;
}

.container_position {
  position: absolute;
}

.btn {
  width: 140px;
  height: 40px;
  background-color: #0BC069;
  border-radius: 20px;
  border: 1px solid #0BC069;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

.code {
  width: 100px;
  background-color: #0BC069;
  /* border-radius: 20px; */
  border: 1px solid #0BC069;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

.code:hover {
  background-color: #fff;
  color: #0BC069;
  border: 1px solid #0BC069;
}

.form_title {
  font-size: 22px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
}

.flip-horizontal-bottom-register {
  animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

.register_input {
  width: 274px;
  height: 40px;
  border-radius: 5px;
  margin-right: 15px;
}

.register_input_address {
  width: 845px;
  height: 40px;
  border-radius: 5px;
  margin-right: 15px;
  margin-top: 16px;
}

.register_title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 15px;
}

.getCode1 {
  position: absolute;
  right: 45px;
  background-color: #F8B04B;
  border-radius: 50px;
  color: white;
  /* cursor: pointer; */
}

.button_return {
  background-color: #F8B04B;
  border-radius: 50px;
  color: white;
  width: 180px;
  height: 50px;
}

.button_submit {
  width: 630px;
  height: 50px;
  border-radius: 50px;
  color: white;
  background-color: #2BA66D;
  margin-left: 30px;
}


@keyframes slide-right {
  0% {
    transform: translateX(-450px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(450px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes flip-horizontal-bottom {
  0% {
    transform: rotateX(-180deg);
  }

  100% {
    transform: rotateX(0);
  }
}