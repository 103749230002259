.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-l {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-r {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-sa {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.row-w {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.col-t {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.col-b {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.col-sa {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.col-sb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.vertical-lt {
  align-items: flex-start;
}

.vertical-rb {
  align-items: flex-end;
}

.hidden {
  display: none;
}

.tc {
  text-align: center;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.w100px {
  width: 100px;
}

.h100 {
  height: 100%;
}

.bg-c000 {
  background-color: #000;
}

.bg-cfff {
  background-color: #fff;
}

.c000 {
  color: #000;
}

.c333 {
  color: #333;
}

.c666 {
  color: #666;
}

.c999 {
  color: #999;
}

.cccc {
  color: #cccc;
}

.cfff {
  color: #fff;
}

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz20 {
  font-size: 20px;
}

.fz22 {
  font-size: 22px;
}

.fz24 {
  font-size: 24px;
}

.fz26 {
  font-size: 26px;
}

.fz28 {
  font-size: 28px;
}

.fz30 {
  font-size: 30px;
}

.fz32 {
  font-size: 32px;
}

.fz35 {
  font-size: 35px;
}

.fz40 {
  font-size: 40px;
}

.fz60 {
  font-size: 60px;
}

.fw600 {
  font-weight: 600;
}

.wsn {
  white-space: nowrap;
}

.content-padding {
  padding: 10px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.ellipsis-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.underline {
  text-decoration: underline
}

.none-select {
  user-select: none;
}

.ant-select-arrow {
  /* color: white; */
  color: #b8b8b8;
}

.bar-item {
  margin-right: 12px;
  padding: 0 14px;
  border-radius: 4px;
  cursor: pointer;
}

.bar-item-color {
  background-color: #2BA66D;
  color: #fff;
}

.bar {
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  height: 100%;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: white;
}

.margin-r10 {
  margin-right: 10px;
}

.margin-r5 {
  margin-right: 5px;
}

.margin-r15 {
  margin-right: 15px;
}

.margin-r20 {
  margin-right: 20px;
}

.margin-r30 {
  margin-right: 30px;
}

.margin-r40 {
  margin-right: 40px;
}

.margin-r100 {
  margin-right: 100px;
}

.margin-r197 {
  margin-right: 197px;
}

.margin-b10 {
  margin-bottom: 10px;
}

.margin-b5 {
  margin-bottom: 5px;
}

.margin-b20 {
  margin-bottom: 20px;
}

.margin-b70 {
  margin-bottom: 70px;
}

.margin-b105 {
  margin-bottom: 105px;
}

.margin-l40 {
  margin-left: 40px;
}

.margin-l0 {
  margin-left: 0 !important;
}

.margin-l5 {
  margin-left: 5px;
}

.margin-l10 {
  margin-left: 10px;
}

.margin-l15 {
  margin-left: 15px;
}

.margin-l20 {
  margin-left: 20px;
}

.margin-l40 {
  margin-left: 40px;
}

.margin-l25 {
  margin-left: 25px;
}

.margin-t10 {
  margin-top: 10px;
}

.margin-t5 {
  margin-top: 5px;
}

.margin-t20 {
  margin-top: 20px;
}

.margin-t25 {
  margin-top: 25px;
}

.margin-t30 {
  margin-top: 30px;
}

.margin-t40 {
  margin-top: 40px;
}

.margin-t50 {
  margin-top: 50px;
}

.margin-t70 {
  margin-top: 70px;
}

.margin-t108 {
  margin-top: 108px;
}

.margin-lr5 {
  margin: 0 5px;
}

.margin-lr20 {
  margin: 0 20px;
}

.margin-tb10 {
  margin: 10px 0;
}

.padding-l5 {
  padding-left: 5px;
}

.padding-l10 {
  padding-left: 10px;
}

.padding-l20 {
  padding-left: 20px;
}

.padding-t5 {
  padding-top: 5px;
}

.padding-t10 {
  padding-top: 10px;
}

.padding-t20 {
  padding-top: 20px;
}

.padding-t25 {
  padding-top: 25px;
}

.padding-r5 {
  padding-right: 5px;
}

.padding-r10 {
  padding-right: 10px !important;
}

.padding-r20 {
  padding-right: 20px;
}

.padding-b5 {
  padding-bottom: 5px;
}

.padding-b10 {
  padding-bottom: 10px;
}

.padding-b20 {
  padding-bottom: 20px;
}

.padding-b25 {
  padding-bottom: 25px;
}

.padding0 {
  padding: 0;
}

.padding2 {
  padding: 2px !important;
}

.padding5 {
  padding: 5px !important;
}

.padding7 {
  padding: 7px !important;
}

.padding10 {
  padding: 10px;
}

.padding15 {
  padding: 15px;
}

.padding1020 {
  padding: 10px 20px;
}

.padding20 {
  padding: 20px;
}

.padding25 {
  padding: 25px;
}

.padding30 {
  padding: 30px;
}

.padding40 {
  padding: 40px;
}

.padding66 {
  padding: 66px;
}

.padding-tb5 {
  padding: 5px 0;
}

.padding-tb10 {
  padding: 10px 0;
}

.padding-tb20 {
  padding: 20px 0;
}

.padding-lr10 {
  padding: 0 10px;
}

.padding-lr5 {
  padding: 0 5px;
}

.padding-lr3 {
  padding: 0 3px;
}

.padding-a510 {
  padding: 5px 10px;
}

.padding-lr20 {
  padding: 0 20px;
}

.padding0 {
  padding: 0 !important;
}

.img_size {
  margin: 19px 0 19px 33px;
  width: 64px;
  height: 64px;
}

.w295 {
  width: 295px;
}

.w1300 {
  width: 1300px;
}

.w1200 {
  width: 1200px;
}

.w850 {
  width: 850px;
}

.w750 {
  width: 750px;
}

.w700 {
  width: 700px;
}

.w680 {
  width: 680px;
}

.w640 {
  width: 640px;
}

.w600 {
  width: 600px;
}

.wm600 {
  max-width: 600px;
}

.w550 {
  width: 550px;
}

.w500 {
  width: 500px;
}

.w486 {
  width: 486px;
}

.w440 {
  width: 440px;
}

.w400 {
  width: 400px;
}

.w360 {
  width: 360px;
}

.w330 {
  width: 330px;
}

.w300 {
  width: 300px;
}

.mw300 {
  max-width: 300px;
}

.w250 {
  width: 250px !important;
}

.mw224 {
  max-width: 224px;
}

.w220 {
  width: 220px !important;
}

.w200 {
  width: 200px;
}

.w198 {
  width: 198px;
}

.w180 {
  width: 180px;
}

.w166 {
  width: 166px;
}

.w160 {
  width: 160px;
}


.w155 {
  width: 155px !important;
}

.w150 {
  width: 150px;
}

.w140 {
  width: 140px;
}

.w130 {
  width: 130px;
}

.w126 {
  width: 126px;
}

.w120 {
  width: 120px;
}

.w115 {
  width: 115px;
}

.w110 {
  width: 110px !important;
}

.mw110 {
  max-width: 110px;
}

.w90 {
  width: 90px;
}

.w80 {
  width: 80px;
}

.miw80 {
  min-width: 80px !important;
}

.w70 {
  width: 70px;
}

.w60 {
  width: 60px;
}

.w40 {
  width: 40px;
}

.w35 {
  width: 35px;
}

.w30 {
  width: 30px;
}

.max170 {
  max-width: 170px;
}

.max200 {
  max-width: 220px;
}


.h480 {
  height: 480px;
}

.h400 {
  height: 400px;
}

.h340 {
  height: 340px;
}

.minh340 {
  min-height: 340px;
}

.h300 {
  height: 300px;
}

.h200 {
  height: 200px;
}

.h160 {
  height: 160px;
}

.mh160 {
  max-height: 160px;
}

.h120 {
  height: 120px !important;
}

.h110 {
  height: 110px !important;
}

.h90 {
  height: 90px !important;
}

.h70 {
  height: 70px;
}

.h50 {
  height: 50px;
}

.h40 {
  height: 40px;
}

.h35 {
  height: 35px;
}

.h32 {
  height: 32px;
  line-height: 32px;
}

.mh30 {
  min-height: 30px;
}

.lh15 {
  line-height: 1.5;
}

.lh65 {
  line-height: 65px;
}

.title {
  margin-top: -280px;
  margin-bottom: 260px;
}

.ant-radio-inner:after {
  background-color: #0BC069;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #0BC069;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #0BC069;
}

.radius35 {
  border-radius: 35px;
}

.white-space {
  white-space: pre;
}

.bold {
  font-weight: bold;
}

.radius5 {
  border-radius: 5px;
}

.position {
  position: relative;
}

.loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
}

.overline {
  overflow: hidden;
}

.scroll-y {
  overflow-y: scroll;
  overflow-x: hidden;
}

.bord1 {
  border: 1px solid #d3d3d38a;
}

.bord2 {
  border: 1px solid #1890ff;
}

.bord3 {
  border: 1px solid #ccc;
}

.bord-l1 {
  border-left: 1px solid #ccc;
}

.bottomLine {
  border-bottom: 2px dashed #d3d3d38a;
}

.bottomLine2 {
  border-bottom: 2px solid #d3d3d38a;
}

.solid1 {
  border-bottom: 1px solid #BBBBBB;
}

.solid2 {
  border-top: 1px solid #BBBBBB;
}

.btn-icon {
  width: 30px;
  height: 30px;
}


.show {
  display: inline-block;
}

.opacity0 {
  opacity: 0;
}

.table-line>div:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.lineCut {
  overflow-wrap: anywhere;
}

.over {
  overflow: auto;
}

/* 超出1行省略 */
.oneline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 超出2行省略 */
.twoline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/* 超出3行省略 */
.threeline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}


.el-radio div input[aria-hidden="true"] {
  display: none !important;
}

.aria-hidden div input[aria-hidden="true"] {
  display: none !important;
}

.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none !important;
}