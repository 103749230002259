.back-color0 {
  background-color: #fff;
}

.back-color1 {
  background-color: #4B4B6B;
}

.back-color2 {
  background-color: #FAFAFA;
}

.back-color4 {
  background-color: #201d37;
}


.back-color5 {
  background-color: #1890FF;
}

.background1 {
  background: linear-gradient(135deg, #1890FF, #1890FF 50%, transparent 50%, transparent 100%);
}

.background2 {
  background: linear-gradient(135deg, #f3a75e, #f3a75e 50%, transparent 50%, transparent 100%);
}

.background3 {
  background: linear-gradient(135deg, #fd3232, #fd3232 50%, transparent 50%, transparent 100%);
}

.back-color6 {
  background-color: #FFE1E3;
}

.back-color7 {
  background-color: #EEF8F4;
}

.back-color8 {
  background-color: #F8B04B;
}

.back-color9 {
  background-color: #2BA66D;
}

.back-color10 {
  background-color: #E8E6E6;
}

.back-color11 {
  background-color: #DA2F32;
}

.back-color12 {
  background-color: #07C160;
}

.back-color13 {
  background-color: #40a9ff;
}

.back-color14 {
  background-color: #79bff8;
}



.color0 {
  color: #fff;
}

.color1 {
  color: #4B4B6B;
}

.color2 {
  color: #f5222d;
}

.color3 {
  color: #1890ff;
}

.color4 {
  color: #201d37;
}

.color5 {
  color: #BBBBBB;
}

.color6 {
  color: #2BA66D;
}

.color7 {
  color: #727272;
}

.color8 {
  color: black;
}

.color9 {
  color: #aaa;
}

.color10 {
  color: rgb(68, 68, 68);
}



.border {
  border: 1px solid #2BA66D;
}