.state_box {
  width: 90px;
  height: 25px;
  /* background-color: aqua; */
}

.state_color1 {
  background-color: #feefda;
  color: #F8B04B;
}

.state_color2 {
  background-color: #EEF8F4;
  color: #2BA66D;
}

.state_color3 {
  background-color: #FFE1E3;
  color: #DF2633;
}

.button {
  background-color: #2BA66D;
  opacity: 0.7;
  color: white;
  padding: 5px 0;
  border-radius: 5px;
  width: 100%;
  transition: 0.5s;
  cursor: pointer;
}

.button:hover {
  opacity: 1;
}

.inline {
  display: inline;
  cursor: pointer;
  width: 50px;
  border: 1px solid black;
  border-radius: 3px;
}

.width-50 {
  width: 50px;
  text-align: center;
}

.pageStyle {
  padding: 5px 10px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
}

.pageStyle:hover {
  background-color: #e6e6e6;
}

.menu {
  margin: 0 5px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
}

.menu-select1 {
  color: rgba(187, 187, 187, 100);
  background-color: rgba(0, 0, 0, 0.05);
}

.menu-select2 {
  color: #fff;
  background-color: #1890ff;
}


.batch {
  margin-bottom: -10px;
  width: 150px;
  height: 40px;
  /* line-height: 40px; */
  border-radius: 6px;
  opacity: 0.8;
}

.batch:hover {
  opacity: 1;
}

.fixed {
  z-index: 1;
  position: fixed;
  top: 0;
  width: 1603px;
  border-top: 120px solid rgb(228, 233, 247);
  border-bottom: 10px solid rgb(228, 233, 247);
}

.padding66 {
  padding: 66px;
}