.button1{
    border-radius: 5px;
    width: 100%;
    background-color: #1890FF;
    opacity: 0.7;
    color: white;
    padding: 5px 0;
    transition: 0.5s;
    cursor: pointer;
}
.button2{
    border-radius: 5px;
    width: calc(50% - 2px);
    opacity: 0.7;
    color: white;
    padding: 5px 0;
    margin-top: 5px;
    transition: 0.5s;
    cursor: pointer;
}
.color1{
    background-color: #F8B04B;
}
.color2{
    background-color: #2BA66D;
}
.button1:hover, .button2:hover{
    opacity: 1;
}
.inline {
    display: inline;
    cursor: pointer;
    width: 50px;
    border: 1px solid black;
    border-radius: 3px;
}

.width-50 {
    width: 50px;
    text-align: center;
}

.pageStyle{
    padding: 5px 10px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
}
.pageStyle:hover{
    background-color: #e6e6e6;
}