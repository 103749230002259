.quickLabel_div {
  width: 135px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  background-color: #fafafa;
  border: 1px solid #dedcdc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.quickLabel_Color {
  color: #0BC069;
  background-color: white;
  border-bottom: 1px solid transparent;
  border-bottom: 1px solid transparent;
}


.columns-span {
  color: rgb(142, 141, 141);
  font-size: 14px;
}

.columns3-type-span {
  color: red;
  font-size: 14px;
}

.columns2-copy-button {
  width: 100px;
  padding: 5px 0px;
  background-color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  color: white;

  opacity: 0.7;
}

.columns2-copy-button2:hover {
  opacity: 1;
}

.columns2-copy-button2 {
  width: 100px;
  padding: 5px 0px;
  background-color: #ee9f28;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  color: white;

  opacity: 0.7;
}

.columns2-copy-button:hover {
  opacity: 1;
}

.img {
  margin-right: 10px;
  width: 110px;
  height: 110px;
  border-radius: 5px;
}

.reminder_styles {
  margin-left: 40px;
  width: 250px;
  height: 35px;
  opacity: 0.7;
  cursor: pointer;
  background-color: #F91D00;
  border-radius: 5px;
  color: white;
  line-height: 35px;
  text-align: center;
  transition: 0.5s;
}

.reminder_styles:hover {
  opacity: 1;
}