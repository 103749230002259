.property {
  width: 85px;
}

.propertys {
  width: 120px;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.address {
  padding: 0 10px;
  width: 485px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.address-detail {
  width: 350px;
}

.current-address {
  padding: 0 10px;
  width: 400px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.btn-map {
  width: 85px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  border: 1px solid rgb(209, 209, 209);
  background-color: #1890ff;
  cursor: pointer;
}

.remark {
  width: 485px;
  height: 200px !important;
}

.box {
  width: 200px !important;
  height: 155px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.img {}



.btn {
  width: 180px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border: 1px solid #2BA66D;
  border-radius: 5px;
}

.select {
  width: 340px;
  height: 35px;
}

.btn-price {
  width: 135px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
}

.service-box {
  width: 500px;
  height: 95px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;
}

.bail {
  width: 120px;
  height: 35px;
}

.disable {
  width: 500px;
  height: 50px;
}

.area-box {
  width: 850px;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;
}

.circle {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.area {
  width: 190px;
  height: 70px;
  background-image: url('../../resource/png/masterManagement-area.png');
}

.class-line {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
}

.btn-service {
  width: 140px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
}

.img-locate {
  width: 20px;
  height: 20px;
}

.img-delarea {
  position: absolute;
  top: -70px;
  right: -90px;
  width: 30px;
  height: 30px;
}

.btn-modal {
  width: 100px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #2BA66D;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.btn-modal2 {
  /* width: 100px; */
  height: 40px;
  border: 1px solid #2BA66D;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.select-modal {
  width: 150px;
  height: 35px;
}

.select-drawer {
  width: 720px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.account {
  width: 1000px;
}

.img-del {
  width: 30px;
  height: 30px;
}

.amount {
  width: 300px;
}

.border-right {
  border-right: 2px solid rgba(0, 0, 0, 0.15);
}

.btn-finance {
  width: 100px;
  height: 35px;
  line-height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.btn-withdrawal {
  width: 80px;
  height: 30px;
  line-height: 30px;
}

.map {
  height: 500px;
}

.trd {
  padding: 2px 0;
  width: 130px !important;
  min-height: 30px !important;
  /* line-height: 30px; */
  text-align: center;
  border: 1px solid #aaa;
}