.input-service {
  padding-left: 20px;
  width: 300px;
  height: 35px;
}

.btn {
  width: 100px;
  height: 35px;
  line-height: 33px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  cursor: pointer;
}

.input {
  width: 280px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.btn-modal {
  width: 100px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #2BA66D;
  border-radius: 5px;
  text-align: center;
}