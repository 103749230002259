.content {
    position: relative;
    padding: 10px;
    width: 160px;
    height: 160px;
    border: 1px dashed #ccc;
    border-radius: 10px;
}

.content-small {
    padding: 5px;
    width: 120px;
    height: 120px;
}

.content-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.content-div {
    position: absolute;
    top: 10px;
    border-radius: 10px;
    width: 140px;
    height: 140px;
    transition: 0.8s;
}

.content-div-small {
    top: 5px;
    width: 110px;
    height:110px;
}

.pic-icon {
    font-size: 20px;
    display: none;
    padding: 0 5px;
    transition: 0.8s;
}

.content-div:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon {
    display: block;
    color: #fff;
}
.delete{
    position: absolute;
    top:5px;
    right: 2px;
}