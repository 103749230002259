.div-right {
  width: 477px;
}

.div-left {
  /* width: calc(100% - 477px); */
  width: 100%;
}

.right-title {
  background-color: #D4F4E4;
  color: black;
  font-size: 20px;
  width: 477px;
  padding: 18px;
}

.righr-content {
  width: 477px;
  background-color: white;
  font-size: 16px;
  padding: 18px 15px;
}

.a-style {
  width: 29px;
  height: 29px;
  background-color: #0BC069;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  margin-right: 20px;
}

.right-title1 {
  color: #0BC069;
  font-size: 16px;
  text-decoration: underline;
}

.top-style {
  width: 50%;
}

.top-left-title {
  font-size: 20px;
}

.top-left-Input {
  margin-top: 10px;
  margin-left: 40px;
}

.top-left-tips {
  color: red;
  margin: 10px 0;
}

.top-left-button {
  background-color: #0BC069;
  color: white;
  width: 216px;
  padding: 5px 0;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
  transition: 0.5s;
  cursor: pointer;
}

.top-left-button:hover {
  background-color: #0BC069;
  opacity: 1;
}

.split-line {
  border-left: 1px solid #BBBBBB;
  height: 343px;
}

.top-right-empty {
  border: 1px solid #0BC069;
  color: #0BC069;
  width: 185px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #D4F4E4;
  line-height: 30px;
}

.empty-w-style {
  margin-left: 75%;
}

.top-right-empty:hover {
  background-color: #0BC069;
  opacity: 1;
  color: white;
}

.tips-style {
  font-size: 20px;
  color: #BBBBBB;
  width: 100%;
  text-align: center;
}

.choiceType_div {
  width: 185px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  margin-left: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(187, 187, 187, 100);
  border-radius: 5px;
}

.choiceType_Color {
  background-color: #0BC069;
  color: white;
}

.measure-grid {
  width: calc(100% - 150px);
  margin-left: 75px;
}

.measure-grid-radio {
  /* padding: 20px 0; */
  border-top: 1px solid #BBBBBB;
}

.date {
  background-color: #f2f2f2;
  color: rgba(187, 187, 187, 100);
  border: 1px solid rgba(187, 187, 187, 100);
  border-radius: 5px;
  text-align: center;
  margin-left: 20px;
  width: 250px;
  height: 32px;
  line-height: 32px;
}

.dateType {
  width: 300px;
}

.DatePicker {
  margin-left: 20px;
  width: 250px;
}

.tips {
  width: calc(100% + 40px);
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #ffe4e3;
  color: red;
  margin: 0 -20px;
}

.group-div {
  width: 80px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(187, 187, 187, 100);
  border-radius: 5px;
}

.group-div-color {
  background-color: #0BC069;
  color: white;
}

.vertical-line {
  border-left: 1px solid #f0f0f0;
  height: 120px;
  margin: 0 5px;
}

.options0 {
  width: 1px;
  height: 50px;
  background-color: #BBBBBB;
  transform: rotateZ(30deg);
}

.div-remarks-style {
  width: 100%;
  border: 1px solid #0BC069;
  background-color: #D4F4E4;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: #0BC069;

  overflow: hidden;
  /*内容超出后隐藏*/
  text-overflow: ellipsis;
  /* 超出内容显示为省略号*/
  white-space: nowrap;
  /*文本不进行换行*/
  margin-top: 5px;
}

.ant-typography {
  color: #0BC069;
}

.div-remarks-style:hover {
  background-color: #0BC069;
  color: white;
}

.remarks-div-Box {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.title-order-cost {
  width: 100px;
  text-align: right;
  margin-right: 10px;
}

.coupon {
  width: 398px;
  border-radius: 5px;
  border: 1px solid #BBBBBB;
  color: #BBBBBB;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}

.preservation-style {
  width: 238px;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid #BBBBBB;
  color: #BBBBBB;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}

.preservation-style:hover {
  background-color: #f7f7f7f7;
}

.place-order-style {
  background-color: #0BC069;
  color: white;
  width: 505px;
  padding: 5px 0;
  border-radius: 5px;
  text-align: center;
  opacity: 0.7;
  transition: 0.5s;
  cursor: pointer;
  font-size: 20px;
}

.place-order-style:hover {
  background-color: #0BC069;
  opacity: 1;
}

.delete-style:hover {
  color: red;
}

.delete-style {
  font-size: 20px;
}

.overflow-y {
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
}

.room_div_style {
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.room_div_style:hover {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.room_type_div {
  border: 1px solid #0BC069;
  border-radius: 3px;
  padding: 2px 10px;
  background-color: #0BC069;
  color: white;
  opacity: 0.7;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 10px;
}

.room_type_div:hover {
  opacity: 1;
}

.priceStyles {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: calc(100% - 88px);
}

.search_btn {
  z-index: 1;
  position: absolute;
  top: 107px;
  /* top: 0; */
  right: 0;
  width: 100px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  font-size: 16px;
  color: white;
  opacity: 0.7;
  background-color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
}

.search_btn:hover {
  color: white;
  opacity: 1;
  background-color: #0BC069;
  border-radius: 5px;
  font-size: 17px;
}

.operate_btn {
  width: 100px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  color: white;
  opacity: 0.7;
  background-color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
}

.pic-div {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  overflow: hidden;
}

.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.content-div {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 90px;
  transition: 0.5s;
}

.pic {
  width: 100px;
  height: 100px;
  margin-top: -15px;
  border-radius: 8px;
}

.pic:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.pic-icon-E {
  font-size: 20px;
  display: none;
  /* color: red; */
  padding: 0 5px;
  transition: 0.8s;
  position: absolute;
  top: 40px;
  left: 35px;
}

.content-div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.pic-icon {
  position: relative;
  top: 86px;
  width: 98px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2px;
  cursor: pointer;
}

.content-video {
  position: relative;
  padding: 5px;
  width: 250px;
  height: 150px;
  margin-left: 8px;
  /* margin-bottom: 60px; */
  border: 1px solid #ccc;
  border-radius: 10px;
}

.content-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video {
  padding: 3px 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 1px solid #0BC069;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
}

.video-select {
  background-color: #0BC069;
  color: #fff;
}

.video-box {
  margin: 0 10px 10px;
  padding: 6px 10px;
  border: 1px dashed #ddd;
  border-radius: 5px;
}