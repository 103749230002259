.btn {
  width: 100px;
  height: 35px;
  line-height: 33px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}


.input-service {
  width: 250px;
}