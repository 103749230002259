.btn {
  width: 180px;
  height: 45px;
  border: 1px solid 2BA66D;
  border-radius: 5px;
  line-height: 45px;
}

.btn-search {
  width: 110px;
  height: 35px;
  border-radius: 5px;
  line-height: 35px;
}

.select {
  width: 150px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  line-height: 35px;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}