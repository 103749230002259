.columns2-copy-button {
    width: 160px;
    padding: 5px 0px;
    background-color: #d7f3e5;
    color: #0BC069;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    border: 1px solid #0BC069;
    transition: 0.5s;
}

.columns2-copy-button:hover {
    background-color: #0BC069;
    color: white;
}

.columns3-type-span {
    color: red;
    font-size: 14px;
}

.columns-span {
    color: rgba(142, 141, 141, 100);
    font-size: 14px;
}

.left-top-type {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0px;
    left: 0;
    background: linear-gradient(135deg, #f3a75e, #f3a75e 50%, transparent 50%, transparent 100%);
}

.left-top-type-span {
    color: white;
    transform: rotate(-45deg);
    margin-right: 15px;
    margin-top: 4px;
}

.columns1-date-span {
    color: rgba(142, 141, 141, 100);
    font-size: 14px;
    opacity: 0.5;
}

.button {
    background-color: #0BC069;
    opacity: 0.7;
    color: white;
    padding: 5px 0;
    border-radius: 5px;
    width: 100%;
    transition: 0.5s;
    cursor: pointer;
}

.button:hover {
    opacity: 1;
}
.inline {
    display: inline;
    cursor: pointer;
    width: 50px;
    border: 1px solid black;
    border-radius: 3px;
}

.width-50 {
    width: 50px;
    text-align: center;
}

.pageStyle{
    padding: 5px 10px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
}
.pageStyle:hover{
    background-color: #e6e6e6;
}