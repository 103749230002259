.tit {
  width: 100px;
}

.reset {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.count {
  width: 100%;
  height: 300px;
  /* color: #5470C6; */
}

.scroll-area {
  overflow-x: scroll;
}

.selectDate {
  margin-left: 40px;
  margin-right: 10px;
}

.quickly {
  margin-right: 10px;
  width: 65px;
  height: 28px;
  line-height: 28px;
  border-radius: 5px;
  background-color: rgba(242, 242, 242, 100);
  color: rgba(114, 114, 114, 100);
  font-size: 13px;
  text-align: center;
  font-family: Roboto;
  border: 1px solid rgba(205, 205, 205, 100);
  cursor: pointer;
}

.quickly-select {
  margin-right: 10px;
  width: 65px;
  height: 28px;
  line-height: 28px;
  border-radius: 5px;
  background-color: rgba(7, 193, 96, 100);
  color: rgba(248, 248, 248, 100);
  font-size: 13px;
  text-align: center;
  font-family: Roboto;
  border: 1px solid rgba(7, 193, 96, 100);
  cursor: pointer;
}

.summary {
  width: 140px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.summary-finance {
  padding: 0 20px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.provinces-box {
  padding: 0 5px;
  width: 480px;
  height: 70px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.provinces {
  height: 54px;
  width: 380px;
  overflow-y: scroll;
}

.btn {
  padding-left: 6px;
  margin: 0 10px 5px 0;
  width: 80px;
  height: 24px;
  line-height: 24px;
  border-radius: 5px;
  background-color: rgba(242, 242, 242, 100);
  color: rgba(16, 16, 16, 100);
  border: 1px solid rgba(187, 187, 187, 100);
}

.add {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(219, 219, 219, 0.15);
}

.box1 {
  margin-left: 30px;
  margin-right: 5px;
  width: 20px;
  height: 10px;
  line-height: 20px;
  background-color: #F41212;
  text-align: center;
}

.box2 {
  margin-left: 30px;
  margin-right: 5px;
  width: 20px;
  height: 10px;
  line-height: 20px;
  background-color: #FFC439;
  text-align: center;
}

.box3 {
  margin-left: 30px;
  margin-right: 5px;
  width: 20px;
  height: 10px;
  line-height: 20px;
  background-color: #07C160;
  text-align: center;
}

.box4 {
  margin-left: 30px;
  margin-right: 5px;
  width: 20px;
  height: 10px;
  line-height: 20px;
  background-color: #B400F2;
  text-align: center;
}

.box5 {
  margin-left: 30px;
  margin-right: 5px;
  width: 20px;
  height: 10px;
  line-height: 20px;
  background-color: #1890FF;
  text-align: center;
}

.btn-look {
  padding: 3px 8px;
  border-radius: 5px;
  background-color: #FAFAFA;
  color: #fff;
  cursor: pointer;
}

.btn-look-select1 {
  color: rgba(187, 187, 187, 100);
  background-color: rgba(0, 0, 0, 0.05);
}

.btn-look-select2 {
  color: #fff;
  background-color: #07C160;
}

.title {
  padding: 0 12px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #eee;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #F6F6F6;
}

.singleSum {
  width: 116px;
}

.singleSum:not(:last-of-type) {
  border-right: 1px solid #ccc;
}

.sumtr {
  border: 1px solid #aaa;
  color: #07C160;
  color: #f3a75e;
  color: #1890FF;
  color: #07C160;
}