.input-service {
  margin-left: 10px;
  width: 300px;
}

.input-service2 {
  margin-left: 10px;
  width: 285px;
}

.btn {
  width: 100px;
  height: 35px;
  line-height: 33px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.permiss {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1px 8px;
  border-radius: 5px;
  margin: 3px;
}

.area {
  padding-left: 20px;
  width: 160px;
  height: 35px;
}

.property {
  width: 110px;
}

.btn-modal {
  width: 100px;
  height: 40px;
  border: 1px solid #2BA66D;
  border-radius: 5px;
}


.columns2-copy-button {
  width: 100px;
  padding: 5px 0px;
  background-color: #1890FF;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  color: white;

  opacity: 0.7;
}

.columns2-copy-button:hover {
  opacity: 1;
}