.search-line {
  padding-top: 25px;
}

.input-service {
  padding-left: 20px;
  width: 250px;
  height: 35px;
}

.btn {
  width: 100px;
  height: 35px;
  line-height: 33px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  cursor: pointer;
}

.img {
  width: 20px;
  height: 20px;
}