.input-service {
  /* padding-left: 20px; */
  width: 300px;
  height: 35px;
}

.btn {
  width: 100px;
  height: 35px;
  line-height: 33px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  cursor: pointer;
}

.img {
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
}

.order-id {
  margin-top: -12px;
}

.img-address {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.btn-operate {
  padding: 5px;
}

.botton {
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 5px;
  opacity: 0.7;
  color: white;

  cursor: pointer;
}

.botton1_back_color {
  background-color: #2BA66D;
}

.botton2_back_color {
  background-color: #F99200;
}

.botton3_back_color {
  background-color: #1890FF;
}

.botton:hover {
  opacity: 1;
}

.room_div_style {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 1px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.room_instr {
  width: 100%;
  height: 90px;
  text-align: center;
  border-radius: 1px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  overflow-y: auto;
}


.room_div_style:hover {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.remark {
  /* width: 100px; */
  text-align: center;
  border-radius: 1px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.remark:hover {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.room_type_div {
  border: 1px solid #0BC069;
  border-radius: 3px;
  padding: 2px 10px;
  background-color: #0BC069;
  color: white;
  opacity: 0.7;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 10px;
}

.room_type_div:hover {
  opacity: 1;
}

.icon_style {
  font-size: 28px;
  color: #c1c1c1;
  transition: 0.3s;
}

.icon_style:hover {
  color: red;
}

.icon_style1:hover {
  cursor: pointer;
  color: #1890FF;
}


.pic-div {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  overflow: hidden;
}

.pic-icon-select {
  background-color: rgba(255, 0, 0, 0.7);
}

.remarks:hover {
  color: #1890FF;
  opacity: 0.7;
  cursor: pointer;
  text-decoration: underline
}

.pic_box {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 580px;
  height: 150px;
  padding: 10px;
  margin-right: 10px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 10px;
}

.box_style {
  padding: 5px;
  width: 100%;
  height: 220px;
  overflow: hidden;
  overflow-y: auto;
}

.pic-icon-E {
  font-size: 20px;
  display: none;
  /* color: red; */
  padding: 0 5px;
  transition: 0.8s;
  position: absolute;
  top: 40px;
  left: 35px;
}

.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.content-div {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 78px;
  transition: 0.5s;
}

.content-div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.pic {
  width: 100px;
  height: 100px;
  margin-top: -23px;
  border-radius: 8px;
}

.pic:hover {
  background-color: rgba(0, 0, 0, 0.3);
}


.pic-icon {
  position: relative;
  top: 78px;
  width: 98px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2px;
  cursor: pointer;
}

.inline {
  display: inline;
  cursor: pointer;
  width: 50px;
  border: 1px solid black;
  border-radius: 3px;
}

.width-50 {
  width: 50px;
  text-align: center;
}

.pageStyle {
  padding: 5px 10px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
}

.pageStyle:hover {
  background-color: #e6e6e6;
}

.urgeOrder-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background: linear-gradient(135deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
  animation: identifier 1s infinite;
  -webkit-animation: identifier 1s infinite;
  /* transition: 0.5s; */
}

.urgeOrder-type-right {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  background: linear-gradient(225deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
}

.left-top-type-span {
  color: white;
  transform: rotate(-45deg);
  margin-right: 15px;
  margin-top: 4px;
}

.right-top-type-span {
  color: white;
  transform: rotate(45deg);
  margin-left: 15px;
  margin-top: 4px;
}

.select-over {
  max-height: 250px;
  overflow-y: auto;
}

.content-video {
  position: relative;
  padding: 5px;
  width: 165px;
  height: 100px;
  margin-left: 8px;
  /* margin-bottom: 60px; */
  border: 1px solid #ccc;
  border-radius: 10px;
}

.content-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video {
  padding: 3px 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 1px solid #0BC069;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
}

.video-select {
  background-color: #0BC069;
  color: #fff;
}

.video-box {
  margin-bottom: 10px;
  padding: 4px 4px;
  border: 1px dashed #ddd;
  border-radius: 5px;
}

@keyframes identifier {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.2;
  }

  40% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}