.title_style {
  margin-right: 10px;
  width: 70px;
  font-size: 14px;
  font-weight: 500;
}

.splitLine {
  width: 100%;
  height: 2px;
  background-color: #d4d4d4d4;
  border-radius: 2px;
  margin: 20px 0;
}

.quickLabel_div {
  margin: 0 5px 5px 0;
  padding: 0 8px;
  /* width: 120px; */
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(187, 187, 187, 100);
  border-radius: 5px;
}

.quickLabel_div:hover {
  background-color: #0BC069;
  color: white;
}

.inline {
  display: inline;
  cursor: pointer;
  width: 50px;
  border: 1px solid black;
  border-radius: 3px;
}

.width-50 {
  width: 50px;
  text-align: center;
}

.pageStyle {
  padding: 5px 10px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
}

.pageStyle:hover {
  background-color: #e6e6e6;
}

.search {
  margin-left: 50px;
  width: 130px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #eee;
  border-radius: 10px;
}

.order {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.map {
  height: 600px;
}

.icon_style {
  color: #BBBBBB;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.icon_style:hover {
  color: rgba(142, 141, 141, 100);
}

.select-rank {
  height: 40px;
  width: 70px;
}

.menu {
  margin-right: 8px;
  width: 136px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
}

.menu-select1 {
  color: rgba(187, 187, 187, 100);
  background-color: rgba(0, 0, 0, 0.05);
}

.menu-select2 {
  color: #fff;
  background-color: #1890ff;
}