.property {
  width: 85px;
}

.propertys {
  width: 120px;
}

.input {
  width: 200px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.address {
  width: 485px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.remark {
  width: 485px;
  height: 100px !important;
}

.box {
  width: 200px;
  height: 155px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.img {}

.attention {
  width: 577px !important;
  height: 260px;
}

.btn {
  width: 180px;
  height: 45px;
  border: 1px solid 2BA66D;
  border-radius: 5px;
}

.select {
  width: 340px;
  height: 35px;
}

.btn-price {
  width: 135px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
}

.service-box {
  width: 485px;
  height: 95px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;
}

.bail {
  width: 170px;
  height: 35px;
}

.disable {
  width: 500px;
  height: 50px;
}

.area-box {
  width: 850px;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;
}

.circle {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.area {
  width: 190px;
  height: 70px;
  background-image: url('../../resource/png/masterManagement-area.png');
}

.class-line {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.btn-service {
  width: 140px;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
}

.img-locate {
  width: 20px;
  height: 20px;
}

.btn-modal {
  width: 100px;
  height: 40px;
  border: 1px solid #2BA66D;
  border-radius: 5px;
}

.select-modal {
  width: 150px;
  height: 35px;
}

.select-drawer {
  width: 550px;
  height: 50px;
  line-height: 50PX;
}

.account {
  width: 1000px;
}

.img-del {
  width: 30px;
  height: 30px;
}

.amount {
  width: 300px;
}

.border-right {
  border-right: 2px solid rgba(0, 0, 0, 0.15);
}

.btn-finance {
  width: 100px;
  height: 35px;
  line-height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.btn-finance2 {
  width: 100px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.select-head {
  width: 200px;
  height: 35px;
}

.bill-count {
  position: fixed;
  bottom: 70px;
  width: 1230px;
}

.bill-count>div {
  height: 56px;
  width: 1230px;
  line-height: 56px;
}

.discount {
  width: 360px;
  height: 150px;
  border: 5px dashed #fff;
  background-color: #E6E0E0;
}

.border-b {
  border-bottom: 5px dashed #fff;
}

.btn {
  width: 180px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border: 1px solid 2BA66D;
  border-radius: 5px;
}

.provinces {
  width: 450px;
}

.video-box {
  margin: 5px 10px;
  padding: 6px 10px;
  border: 1px dashed #ddd;
  border-radius: 5px;
}



.pic-div {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  overflow: hidden;
}

.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.content-div {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  transition: 0.5s;
}

.pic {
  width: 100px;
  height: 100px;
  /* margin-top: -15px; */
  border-radius: 8px;
}

.pic:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.pic-icon-E {
  font-size: 20px;
  display: none;
  /* color: red; */
  padding: 0 5px;
  transition: 0.8s;
  position: absolute;
  top: 40px;
  left: 35px;
}

.content-div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.pic-icon {
  position: relative;
  top: 88px;
  width: 100px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2px;
  cursor: pointer;
}

.content-video {
  position: relative;
  padding: 5px;
  width: 250px;
  height: 150px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.content-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video {
  padding: 3px 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 1px solid #0BC069;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
}

.video-select {
  background-color: #0BC069;
  color: #fff;
}

.video-box {
  margin: 0 10px 10px;
  padding: 6px 10px;
  border: 1px dashed #ddd;
  border-radius: 5px;
}