.btn {
  width: 100px;
  height: 35px;
  line-height: 33px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.input {
  width: 200px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}


.icon {
  width: 20px;
  height: 20px;
}

.box {
  width: 200px !important;
  height: 155px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}