.left-top-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0px;
  left: 0;

}

.columns-border:first-child .left-top-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
}

.left-top-type-span {
  color: white;
  transform: rotate(-45deg);
  margin-right: 15px;
  margin-top: 4px;
}

.urgeOrder-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background: linear-gradient(135deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
  animation: identifier 1s infinite;
  /* transition: 0.5s; */
}

.columns-border:first-child .urgeOrder-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  /* top: -8px;
  left: 0; */
  background: linear-gradient(135deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
  animation: identifier 1s infinite;
  /* transition: 0.5s; */
}

.right-top-type {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  /* top: -8px;
  right: 4px; */
  background: linear-gradient(225deg, #0BC069, #0BC069 50%, transparent 50%, transparent 100%);
}

.right-top-type-span {
  color: white;
  transform: rotate(45deg);
  margin-left: 15px;
  margin-top: 4px;
}

.urgeOrder-type-right {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  background: linear-gradient(225deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
}

.columns-border:first-child .urgeOrder-type-right {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  /* top: -8px; */
  right: 0;
  background: linear-gradient(225deg, #e40b20, #e40b20 50%, transparent 50%, transparent 100%);
}



.columns-border {
  border-bottom: 1px solid #f0f0f0;
  /* padding: 8px 0; */
  /* width: calc(100% + 22px);
  margin: 0 -11px; */
  width: 100%;
  height: 245px !important;
  position: relative;
}

.columns-border:last-child {
  border-bottom: none;
}

.columns-span {
  color: rgba(142, 141, 141, 100);
  font-size: 14px;
}

.columns3-type-span {
  color: red;
  font-size: 14px;
}

.abnormal_div {
  background-color: #FFE1E3;
  color: red;
  width: 105px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  margin-top: 5px;
}

.abnormal_box {
  width: 100%;
  height: 80px;
  overflow-y: overlay;
}


.remack-overflow {
  overflow: hidden;
  overflow-y: auto;
  word-break: break-all;
}

.remack-overflow-height {
  /* width: 210px; */
  height: 90px;
}

.remack-overflow-height1 {
  height: 120px;
}


.columns2-copy-button {
  width: 140px;
  /* padding: 5px 0px; */
  background-color: #d7f3e5;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid #0BC069;
  transition: 0.5s;
}

.columns2-copy-button:hover {
  background-color: #0BC069;
  color: white;
}

.columns2-reminder-button {
  width: 140px;
  /* padding: 5px 0px; */
  background-color: #f3d7d7;
  color: red;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid red;
  transition: 0.5s;
}

.columns2-reminder-button:hover {
  background-color: rgb(252, 24, 24);
  color: white;
}

.columns1-date-span {
  color: rgba(142, 141, 141, 100);
  font-size: 14px;
  opacity: 0.5;
}

.columns2-remark-span {
  color: #1890FF;
  font-size: 14px;
  text-decoration: underline;
}


.splitLine {
  /* width: 100%; */
  width: calc(100% - 4px);
  height: 2px;
  background-color: #d4d4d4d4;
  border-radius: 2px;
}

.FollowUp-style {
  background: url('../../../resource/png/follow-up.png') no-repeat;
  width: 20px;
  height: 20px;
}

.img-size:hover>.FollowUp-style {
  background: url('../../../resource/png/follow-up1.png') no-repeat;
  width: 20px;
  height: 20px;
}

.FollowUp-color {
  background-color: #f3a75e4b;
}

.FollowUp-color:hover {
  background-color: #f3a75e;
  color: white;
}

.Return-style {
  background: url('../../../resource/png/return.png');
  width: 20px;
  height: 20px;

}

.img-size:hover>.Return-style {
  background: url('../../../resource/png/return1.png');
  width: 20px;
  height: 20px;

}

.Return-color {
  background-color: #1890ff4b;
}

.Return-color:hover {
  background-color: #1890ff;
  color: white;
}

.Advance-style {
  background: url('../../../resource/png/advance.png');
  width: 20px;
  height: 20px;
}

.img-size:hover>.Advance-style {
  background: url('../../../resource/png/advance1.png');
  width: 20px;
  height: 20px;
}

.Advance-color {
  background-color: #0BC0694b;
}

.Advance-color:hover {
  background-color: #0BC069;
  color: white;
}

.Prohibit-style {
  background: url('../../../resource/png/prohibit.png');
  width: 20px;
  height: 20px;

}

.img-size:hover>.Prohibit-style {
  background: url('../../../resource/png/prohibit1.png');
  width: 20px;
  height: 20px;

}

.Prohibit-color {
  background-color: #e40b204b;
}

.Prohibit-color:hover {
  background-color: #e40b20;
  color: white;
}

.img-size {
  width: 86px;
  height: 38px;
  border-radius: 5px;
  font-size: 12px;
  transform: scale(0.8);
}


.style-button1 {
  /* width: 95px; */
  margin-bottom: 2px;
  margin-left: 3px;
  width: calc(50% - 4px);
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  color: white;
  background-color: #F99200;
  opacity: 0.7;
  cursor: pointer;
}

.style-button2 {
  /* width: 95px; */
  margin-bottom: 2px;
  margin-left: 3px;
  width: calc(50% - 4px);
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  color: white;
  background-color: #0BC069;
  opacity: 0.7;
  cursor: pointer;
}

.style-button3 {
  /* width: 195px; */
  margin-bottom: 2px;
  margin-left: 3px;
  width: calc(50% - 4px);
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  color: white;
  background-color: #1890ff;
  opacity: 0.7;
  cursor: pointer;
}

.style-button4 {
  /* width: 95px; */
  margin-bottom: 2px;
  margin-left: 3px;
  width: calc(50% - 4px);
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  color: white;
  background-color: #f91d00;
  opacity: 0.7;
  cursor: pointer;
}

.style-button1:hover,
.style-button3:hover,
.style-button4:hover,
.style-button2:hover {
  /* background-color: #F99200; */
  opacity: 1;
}


.room_div_style {
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.room_div_style:hover {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.group-div {
  width: 80px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(187, 187, 187, 100);
  border-radius: 5px;
}

.group-div-color {
  background-color: #0BC069;
  color: white;
}

.vertical-line {
  border-left: 1px solid #f0f0f0;
  height: 120px;
  margin: 0 5px;
}

.delete-style:hover {
  color: red;
}

.delete-style {
  font-size: 20px;
}


.modals_style {
  border-radius: 5px;
  background-color: #e1e1e1;
  padding: 5px 15px;
  margin-right: 10px;
  cursor: pointer;
}

.modals_choice_style {
  border-radius: 5px;
  background-color: #0BC069;
  color: white;
  padding: 5px 15px;
  margin-right: 10px;
  cursor: pointer;
}

.top-right-empty {
  border: 1px solid #0BC069;
  color: #0BC069;
  width: 185px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  background-color: #D4F4E4;
  line-height: 30px;
}

.top-right-empty:hover {
  background-color: #0BC069;
  opacity: 1;
  color: white;
}

.title-order-cost {
  width: 120px;
  text-align: right;
  margin-right: 10px;
}

.order_details_style {
  font-size: 16px;
  margin-right: 40px;
}

.display_hide {
  width: 1300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.room_div_style {
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.room_div_style:hover {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.room_type_div {
  border: 1px solid #0BC069;
  border-radius: 3px;
  padding: 2px 10px;
  background-color: #0BC069;
  color: white;
  opacity: 0.7;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 10px;
}

.room_type_div:hover {
  opacity: 1;
}

.options0 {
  width: 1px;
  height: 50px;
  background-color: #BBBBBB;
  transform: rotateZ(30deg);
}

.w465 {
  width: 465px;
}

.submit_time_style {
  color: #dbdbdb;
}

.content_Box_style {
  width: 155px;
  height: 70px;
  transform: scale(0.8);
  font-size: 13px;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding-left: 5px;

}

.content_style {
  width: 117px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Box_left_style {
  width: 2px;
  height: calc(100% + 28px);
  background-color: #dbdbdb;

  position: absolute;
  left: -11.5px;
  top: -15px;
}

.allRecord {
  width: 105px;
  padding: 3px 0px;
  background-color: #d7f3e5;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #0BC069;
  transition: 0.5s;
  font-size: 13px;
}

.allRecord:hover {
  background-color: #0BC069;
  color: white;
}


.quickLabel_div {
  width: 130px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  margin-right: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(187, 187, 187, 100);
  border-radius: 5px;
}

.quickLabel_Color {
  background-color: #0BC069;
  color: white;
}

.div-icon-color :hover {
  color: #1890ff;
  cursor: pointer;
}

.grade_style {
  color: white;
  background-color: #f3a75e;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.grade_style2 {
  color: white;
  background-color: #f3a75e;
  border-radius: 10px;
  width: 80px;
  height: 20px;
  line-height: 20px;
}

.icon_style {
  color: #BBBBBB;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.icon_style:hover {
  color: rgba(142, 141, 141, 100);
}

.title_style {
  width: 60px;
}

.labelData_styles {
  width: 680px;
}

.box_styles {
  width: 400px;
  height: 120px;
  border: 1px solid #BBBBBB;
  border-radius: 10px;
  padding: 5px;
  overflow: hidden;
  overflow-y: auto;
}

.box_tip_styles {
  padding: 5px 10px;
  border: 1px solid #BBBBBB;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-left: 5px;

}

.box_tip_styles:hover {
  border: 1px solid #1890ff;
}

.color_dele:hover {
  color: red;
}

.bt_styles {
  padding: 5px 20px;
  color: white;
  background-color: #0BC069;
  border-radius: 5px;
  opacity: 0.7;
  cursor: pointer;
  transition: 0.5s;
}

.bt_styles:hover {
  opacity: 1;
}

.priceChange {
  margin-left: 120px;
  width: 780px;
  /* height: 230px; */
}

.pic-div {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  overflow: hidden;
}

.pic-icon-select {
  background-color: rgba(255, 0, 0, 0.7);
}

.remarks:hover {
  color: #1890FF;
  opacity: 0.7;
  cursor: pointer;
  text-decoration: underline
}

.pic_box {
  border: 1px solid #ccc;
  border-radius: 15px;
  width: 500px;
  height: 150px;
  padding: 10px;
  margin-right: 10px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 10px;
}

.box_style {
  width: 100%;
  height: 220px;
  overflow: hidden;
  overflow-y: auto;
}

.pic-icon-E {
  font-size: 20px;
  display: none;
  /* color: red; */
  padding: 0 5px;
  transition: 0.8s;
  position: absolute;
  top: 40px;
  left: 35px;
}

.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.content-div {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 78px;
  transition: 0.5s;
}


.content-div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.content-div2:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div2:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.pic-remark {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.pic {
  width: 100px;
  height: 100px;
  margin-top: -15px;
  border-radius: 8px;
}


.pic:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.pic2:hover {
  background-color: rgba(0, 0, 0, 0.3);
}


.pic-icon {
  position: relative;
  top: 88px;
  width: 100px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2px;
  cursor: pointer;
}

.picBox {
  width: 100px;
  height: 100px;
  border: 1px solid #BBBBBB;
  border-radius: 5px;
}

.img {
  width: 70px;
  height: 70px;
}

.quick-remark {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #eee;
  cursor: pointer;
}

.fast-remark {
  padding: 1px 10px;
  margin-bottom: 3px;
  border-radius: 3px;
  background-color: #eee;
  cursor: pointer;
}

.tong {
  position: absolute;
  right: 50px;
  top: -25px;
  width: 70px;
  height: 70px;
  opacity: 0.8;
}


.content {
  width: 110px;
  height: 110px;
  padding: 3px;
  margin: 2px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  position: relative;
}

.content-div {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 90px;
  transition: 0.5s;
}

.content-div2 {
  position: absolute;
  top: 3px;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  transition: 0.5s;
}

.pic {
  width: 100px;
  height: 100px;
  margin-top: -15px;
  border-radius: 8px;
}

.pic2 {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.pic:hover {
  background-color: rgba(0, 0, 0, 0.3);
}


.content-div:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.content-div:hover .pic-icon-E {
  display: block;
  color: #fff;
}

.content-video {
  position: relative;
  padding: 5px;
  width: 250px;
  height: 150px;
  margin-left: 8px;
  /* margin-bottom: 60px; */
  border: 1px solid #ccc;
  border-radius: 10px;
}

.content-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video {
  padding: 3px 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 1px solid #0BC069;
  color: #0BC069;
  border-radius: 5px;
  cursor: pointer;
}

.video-select {
  background-color: #0BC069;
  color: #fff;
}

.video-box {
  margin: 0 10px 10px;
  padding: 6px 10px;
  border: 1px dashed #ddd;
  border-radius: 5px;
}

.trd {
  width: 180px !important;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #aaa;
}

.choiceType_div {
  width: 140px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  margin-left: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(187, 187, 187, 100);
  border-radius: 5px;
}

.choiceType_Color {
  background-color: #0BC069;
  color: white;
}

.after {
  padding: 10px;
  border: 1px dashed #ccc;
}

.box {
  border: 1px solid #aaa;
}

.feedLine:not(:last-child) {
  border-bottom: 1px dashed #ccc;
}

@keyframes identifier {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.2;
  }

  40% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}