.menu {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #2BA66D;
}

.select1 {
  color: rgba(160, 160, 160, 100);
  background-color: rgba(0, 0, 0, 0.05);
}

.select2 {
  background-color: #2BA66D;
  color: #fff;
}

.remark {
  padding: 4px 8px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #2BA66D;
  color: #fff;
  cursor: pointer;
}

.border-all:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.border-left {
  border-left: 1px solid #ccc;
}

.remark-info {
  padding: 3px 10px;
}

.toSelect:hover {
  cursor: pointer;
  color: #fff;
  background-color: #1890ff;
  border: 1px solid #1890ff;
}